import { v4 as uuidv4 } from 'uuid';

export const cocktails = [
    {
        id: 100,
        name: 'OLD FASHIONED',
        description: 'Whiskey, Sugar cube, Bitters',
        price: '15'
    },
    {
        id: 101,
        name: 'MANHATTAN',
        description: 'Rye Whiskey, Sweet Vermouth, Bitters',
        price: '15'
    },
    {
        id: 102,
        name: 'SIDE CAR',
        description: 'Cognac, Triple Sec, Lemon',
        price: '16'
    },
    {
        id: 104,
        name: 'COSMOPOLITAN',
        description: 'Vodka, Triple Sec, Lime, Cranberry',
        price: '15'
    },
    {
        id: 105,
        name: 'SEX ON THE BEACH',
        description: 'Vodka, Peach Schnapps, Orange, Cranberry',
        price: '15'
    },
    {
        id: 106,
        name: 'MOJITO',
        description: 'Rum, Lime, Simple syrup, Mint',
        secondDesc: 'Strawberry, Passionfruit, Mango +$1',
        price: '15'
    },
    {
        id: 107,
        name: 'MARGARITA',
        description: 'Blanco Tequila, Lime, Triple Sec, Agave',
        secondDesc: 'Spicy, Strawberry, Passionfruit, Mango +$1',
        price: '15'
    },
    {
        id: 108,
        name: 'PALOMA',
        description: 'Blanco Tequila, Lime, Grapefruit, Triple Sec, Agave',
        price: '15'
    },
    {
        id: 109,
        name: 'GIMLET',
        description: 'Gin, Lime, Simple syrup',
        price: '15'
    },
    {
        id: 110,
        name: 'NEGRONI',
        description: 'Gin, Sweet Vermouth, Campari',
        price: '15'
    },
    {
        id: 111,
        name: 'APEROL SPRITZ',
        description: 'Aperol, Prosecco, Seltzer',
        price: '15'
    },
    {
        id: 112,
        name: 'NAKED & FAMOUS',
        description: 'Mezcal, Aperol, Lime,Yellow Chartreuse',
        price: '15'
    },
    {
        id: 113,
        name: 'PAPER PLANE',
        description: 'Whiskey, Amaro, Aperol, Lemon',
        price: '15'
    },
    {
        id: 114,
        name: 'JUNGLE BIRD',
        description: 'Rum, Campari, Pineapple, Lime',
        price: '15'
    }
]


export const signatureCocktails = [
    {
        id: 114,
        name: 'PEMA LISA',
        description: 'Titos Vodka, Lemon juice, Simple syrup, Strawberry puree, Egg white',
        price: '18'
    },
    {
        id: 115,
        name: 'BASANTA',
        description: 'Cucumber infused Gin, Pineapple juice, Lime juice, Cointreau, Agave',
        price: '18'
    },
    {
        id: 116,
        name: 'CARIBBEAN WHISPERS',
        description: 'Rum, Lime, Campari, Pineapple juice, Simple syrup',
        price: '17'
    },
    {
        id: 117,
        name: 'PHAYUL',
        description: 'Makkoli, Tangy Mexican puree, Lemon juice, Simple syrup',
        price: '15'
    },
    {
        id: 118,
        name: 'UPTOP TWIST',
        description: 'Soju, Strawberry puree, Pineapple, Orange',
        price: '15'
    },
    {
        id: 119,
        name: 'GOLDEN HOUR',
        description: 'Tequila, Aperol, Passion Fruit, Lime, Simple Syrup',
        price: '17'
    },
    {
        id: 120,
        name: 'SMOKY SUNSET',
        description: 'Mezcal, Pineapple, Lime, Simple Syrup, Egg White',
        price: '18'
    },
    {
        id: 121,
        name: 'URBAN REFRESHER',
        description: 'Whiskey, Aperol, Lime, Ginger Syrup, Agave. A refreshing mix of bold flavors',
        price: '18'
    },
]

export const beers = [
    {
        id: 119,
        name: 'CORONA',
        price: '7/35'
    },
    {
        id: 120,
        name: 'HEINEKEN',
        price: '7/35'
    },
    {
        id: 121,
        name: 'MODELO',
        price: '7/35'
    },
    {
        id: 122,
        name: 'STELLA',
        price: '7/35'
    },
    {
        id: 123,
        name: 'BLUEMOON',
        price: '7/35'
    },
    {
        id: 124,
        name: 'BUDWISER',
        price: '7/35'
    },
    {
        id: 125,
        name: 'SAPPORO',
        price: '7/35'
    },
    {
        id: 126,
        name: 'LAGUNITAS IPA',
        price: '7/35'
    },
    {
        id: 129,
        name: 'HIGH NOON',
        price: '7/35'
    },
    {
        id: 130,
        name: 'WHITE CLAW',
        price: '7/35'
    },
    {
        id: uuidv4(),
        name: 'GORKHA BEER',
        price: '8/41'
    },
    {
        id: uuidv4(),
        name: 'NEPAL ICE',
        price: '8/41'
    },
    {
        id: uuidv4(),
        name: 'BARASINGHA',
        price: '8/41'
    },
    {
        id: uuidv4(),
        name: 'TAJ MAHAL',
        price: '8/41'
    }
]

export const draftBeers = [
    {
        id: uuidv4(),
        name: 'UPTOP LIGHT',
        price: '8'
    },
    {
        id: 129,
        name: 'PERONI',
        description: 'Premium Pilsner',
        price: '8'
    },
    {
        id: 130,
        name: 'YUENGLING',
        description: 'Amber Lager',
        price: '8'
    },
    {
        id: 131,
        name: 'MERMAN',
        description: 'NY IPA',
        price: '8'
    },
]

export const wines = [
    {
        id: 133,
        name: 'CABERNET SAUVIGNON',
        description: 'red',
        price: '12/40'
    },
    {
        id: 134,
        name: 'MERLOT',
        description: 'red',
        price: '12/40'
    },
    {
        id: 135,
        name: 'PINOT NOIR',
        description: 'red',
        price: '12/40'
    },
    {
        id: 136,
        name: 'CHARDONNAY',
        description: 'white',
        price: '12/40'
    },
    {
        id: 137,
        name: 'SAUVIGNON BLANC',
        description: 'white',
        price: '12/40'
    },
    {
        id: 138,
        name: 'PINOT GRIGIO',
        description: 'white',
        price: '12/40'
    },
    {
        id: 139,
        name: 'RIESLING',
        description: 'white',
        price: '12/40'
    },
    {
        id: 140,
        name: 'ROSÉ',
        description: 'Sparkling',
        price: '13/45'
    },
    {
        id: 141,
        name: 'PROSECCO',
        description: 'Sparkling',
        price: '13/45'
    },
]

export const guestsFavorites = [
    {
        id: uuidv4(),
        name: 'Jagerbomb Shot',
        price: '13'
    },
    {
        id: uuidv4(),
        name: 'Green Tea Shot',
        price: '10'
    },
    {
        id: uuidv4(),
        name: 'Lemon Drop Shot',
        price: '10'
    },
    {
        id: uuidv4(),
        name: 'Pickle Back Shot',
        price: '13'
    },
    {
        id: uuidv4(),
        name: 'KTM Shot',
        price: '10'
    },

]
